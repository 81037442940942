<template>
  <b-tooltip
    type="is-dark"
    :triggers="['click']"
    :auto-close="['outside', 'escape']"
    size="is-large"
    multilined
  >
    <template v-slot:content>
      <div style="white-space: pre-line; text-align: left;">
        <h6 class="title is-6 has-text-white">Contact</h6>
        {{ selectedSwapContact }}
      </div>
    </template>
    <b-button rounded size="is-small" @click="fetchContact(id)" class="contact"
      >Voir le contact</b-button
    >
  </b-tooltip>
</template>

<script>
import "@/mybulma.scss";
import swapServices from "@/services/swapServices";

export default {
  name: "ContactButton",
  props: {
    id: String,
  },
  data: function() {
    return {
      selectedSwapContact: undefined,
    };
  },
  methods: {
    fetchContact: async function(swapId) {
      this.selectedSwapContact = undefined;
      swapServices.fetchContact(swapId).then((response) => {
        this.selectedSwapContact = response.data.contact;
      });
    },
  },
};
</script>
