<template>
  <section class="app-main">
    <div class="container is-fluid is-marginless app-content">
      <div class="columns head is-vcentered">
        <div class="column is-offset-3 is-6 has-text-centered">
          <h3 class="title is-size-3 has-text-weight-normal">Echanger un service</h3>
        </div>
      </div>
      <div class="animated m-b-30 has-text-centered">
        <div class="tile is-ancestor">
            Cette interface a pour objectif de faciliter la mise en relation des coopérateurs souhaitant échanger leurs services.
        </div>
        <div class="tile is-ancestor">
            C’est à vous de contacter la coopératrice/le coopérateur grâce aux informations de contact, afin de s'accorder sur l'échange.
        </div>
        <div class="tile is-ancestor">
            Comment cela fonctionne ?  <a href="https://drive.google.com/file/d/1tF3t3-IbpP17KWNBytjDc9GRFLlPJUZf/view?t=4" target="_blank" class="m-l-5">Voici une vidéo explicative.</a>
        </div>
      </div>
      <div class="animated m-b-30">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-success">
            <div class="tile is-child box">
              <h4 class="title is-size-4">1. Je regarde si un service proposé à l'échange par un coopérateur me convient</h4>
              <!-- <div class="m-b-20">Je regarde si un service proposé à l'échange par un coopérateur me convient :</div> -->
                <!-- <b-field label="Filtrer :">
                <div class="columns">
                  <div class="column">
                    <p>Jours</p>
                    <multiselect
                      v-model="joursSelected"
                      tag-placeholder="Selection des jours"
                      placeholder="Selection des jours"
                      label="name"
                      selectLabel
                      deselectLabel
                      track-by="code"
                      :options="options"
                      :multiple="true"
                      :taggable="true"
                      class="multiselect"
                    ></multiselect>
                  </div>
                  <div class="column">
                    <p>Horaires</p>
                    <multiselect
                      v-model="horairesSelected"
                      tag-placeholder="Selection des heures"
                      placeholder="Selection des heures"
                      label="name"
                      selectLabel
                      deselectLabel
                      track-by="code"
                      :options="optionsHoraires"
                      :multiple="true"
                      :taggable="true"
                      class="multiselect"
                    ></multiselect>
                  </div>
                  <div class="column">
                    <p>A partir de</p>
                    <b-datepicker
                      class="start-from"
                      placeholder="Sélectionner une date...."
                      v-model="selectFrom"
                      editable
                      :min-date="new Date()"
                    >
                    </b-datepicker>
                  </div>
                </div>
                </b-field> -->
                <b-field class="switchOption" v-if="!volant">
                  <b-switch type="is-success" v-model="highlight">Mettre en avant les services proposés dont les préférences mentionnées correspondent à mon créneau :</b-switch>
                  <b-tag :class="highlight ? 'highlight tag-highlighted' : ''">{{ myShift }}</b-tag>
                </b-field>
                <b-field>
                  <b-table
                    v-if="filteredSwaps.length"
                    :data="filteredSwaps"
                    :bordered="false"
                    :striped="true"
                    :narrowed="true"
                    :hoverable="true"
                    :loading="false"
                    :focusable="true"
                    :mobile-cards="false"
                    :paginated="true"
                    per-page="20"
                    :pagination-rounded="true"
                    pagination-size="is-small">
                        <b-table-column field="date" label="Date du service proposé" numeric v-slot="props" :td-attrs="(row) => ({ class: highlightedIds.includes(row._id) && highlight ? 'highlight date' : 'date' })">
                            {{ formatDate(props.row.shiftDate) }}
                        </b-table-column>
                        <b-table-column field="preferences" label="Disponibilités du coopérateur" numeric v-slot="props" :td-attrs="(row) => ({ class: highlightedIds.includes(row._id) && highlight ? 'highlight' : '' })" width="350px">
                          <b-tag v-for="(shift, index) in props.row.preferedShifts" :class="highlight && shift === myShift ? 'm-1 tag tag-highlighted' : 'm-1 tag'" :key="index">{{ shift }}</b-tag>
                        </b-table-column>
                        <b-table-column field="contact" numeric v-slot="props" :td-attrs="(row) => ({ class: highlightedIds.includes(row._id) && highlight ? 'highlight' : '' })">
                            <ContactButton :id="props.row._id"></ContactButton>
                        </b-table-column>
                        <b-table-column field="publishDate" label="Publié le" numeric v-slot="props" :td-attrs="(row) => ({ class: highlightedIds.includes(row._id) && highlight ? 'highlight' : '' })">
                            {{ formatShortDate(props.row.created_at) }}
                        </b-table-column>
                        <b-table-column field="favorites" label="Favoris" numeric v-slot="props" :td-attrs="(row) => ({ class: highlightedIds.includes(row._id) && highlight ? 'highlight' : '' })">
                          <div class="views">
                            <div class="views">
                              <b-icon size="is-small" icon="star-o" />
                              <div class="view is-size-7">{{ props.row.nbFavorites }}</div>
                            </div>
                            <div class="views">
                              <b-icon size="is-small" icon="eye" />
                              <div class="view is-size-7">{{ props.row.nbViews }}</div>
                            </div>
                          </div>
                        </b-table-column>
                        <b-table-column field="save" numeric v-slot="props" :td-attrs="(row) => ({ class: highlightedIds.includes(row._id) && highlight ? 'highlight' : '' })">
                        <b-button v-if="checkIfFavorite(props.row._id)" rounded @click="save(props.row._id)" icon-left='star' size="is-small" class="save">
                          Retirer de mes favoris
                        </b-button>
                        <b-button v-else rounded @click="save(props.row._id)" icon-left='star-o' size="is-small" class="save">
                          Sauvegarder en favoris
                        </b-button>
                    </b-table-column>
                  </b-table>
                  <div v-else class="empty">Pas d'échanges disponibles</div>
                </b-field>
                <div class="m-t-50">
                  <h4 class="title is-size-4">2. Si aucun service ne me convient, je propose un échange</h4>
                  <router-link to="/propose/new">
                    <b-button type="is-primary">Je propose mon service à l'échange</b-button>
                  </router-link>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="animated m-b-30">
        <div class="tile is-ancestor">
          <div class="tile is-parent is-flex-direction-column">
            <div class="tile is-child box">
              <h4 class="title is-size-4">Les services que j'ai actuellement publiés à l'échange</h4>
              <div v-if="!publishedSwaps.length" class="empty">Aucun service publié</div>
              <div v-else v-for="service in publishedSwaps" :key="service._id" class="line">
                {{ formatDate(service.shiftDate) }}
                <router-link :to="'/propose/' + service._id">
                  <b-button class="button" type="is-light" size="is-small">Modifier</b-button>
                </router-link>

                <b-button class="button" type="is-light is-danger" @click="deleteSwap(service._id)" size="is-small">Supprimer</b-button>
              </div>
            </div>
            <div class="tile is-child box">
              <h4 class="title is-size-4">
                Les services que j'ai sauvegardés comme favoris
                <b-icon size="is-small" icon="star-o" class="m-l-10" />
              </h4>
                <div v-if="!favoriteSwaps.length" class="empty">Aucun service sauvegardé</div>
                <b-table
                  v-else
                  :data="favoriteSwaps"
                  :bordered="false"
                  :striped="true"
                  :narrowed="true"
                  :hoverable="true"
                  :loading="false"
                  :focusable="true"
                  :mobile-cards="false">
                    <b-table-column field="date" label="Date du service proposé" numeric v-slot="props" :td-attrs="(row) => ({ class: new Date(row.shiftDate) < new Date() ? 'past' : '' })">
                        {{ formatDate(props.row.shiftDate) }}
                    </b-table-column>
                    <b-table-column field="preferences" label="Disponibilités du coopérateur" numeric v-slot="props" :td-attrs="(row) => ({ class: new Date(row.shiftDate) < new Date() ? 'past' : '' })" width="350px">
                      <b-tag v-for="(shift, index) in props.row.preferedShifts" class="m-1" :key="index">{{ shift }}</b-tag>
                    </b-table-column>
                    <b-table-column field="contact" numeric v-slot="props" :td-attrs="(row) => ({ class: new Date(row.shiftDate) < new Date() ? 'past' : '' })">
                      <ContactButton :id="props.row._id"></ContactButton>
                    </b-table-column>
                    <b-table-column field="publishDate" label="Publié le" numeric v-slot="props" :td-attrs="(row) => ({ class: new Date(row.shiftDate) < new Date() ? 'past' : '' })">
                        {{ formatShortDate(props.row.created_at) }}
                    </b-table-column>
                    <b-table-column field="favorites" label="Favoris" numeric v-slot="props" :td-attrs="(row) => ({ class: new Date(row.shiftDate) < new Date() ? 'past' : '' })">
                      <div class="views">
                        <div class="views">
                          <b-icon size="is-small" icon="star-o" />
                          <div class="view is-size-7">{{ props.row.nbFavorites }}</div>
                        </div>
                        <div class="views">
                          <b-icon size="is-small" icon="eye" />
                          <div class="view is-size-7">{{ props.row.nbViews }}</div>
                        </div>
                      </div>
                    </b-table-column>
                    <b-table-column field="save" numeric v-slot="props" :td-attrs="(row) => ({ class: new Date(row.shiftDate) < new Date() ? 'past' : '' })">
                      <b-button v-if="checkIfFavorite(props.row._id)" rounded @click="save(props.row._id)" icon-left='star' size="is-small" class="save">
                        Retirer de mes favoris
                      </b-button>
                      <b-button v-else rounded @click="save(props.row._id)" icon-left='star-o' size="is-small" class="save">
                        Sauvegarder en favoris
                      </b-button>
                    </b-table-column>
                </b-table>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>
    <b-modal
      v-model="isDeleteModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      close-button-aria-label="Close"
      aria-modal>
      <template #default="props">
          <DeleteModal @close="props.close" @swapDeleted="init" :swapId="selectedSwap"></DeleteModal>
      </template>
    </b-modal>
  </section>
</template>

<script>
import "@/mybulma.scss";
import swapServices from "@/services/swapServices";
import date from "@/utils/formatDate";

import ContactButton from '../ContactButton.vue';
import DeleteModal from '../DeleteModal.vue';

export default {
  name: "Exchange",
  components: {
    ContactButton,
    DeleteModal,
  },
  data: function () {
    return {
      mesinfos: "",
      volant: false,
      joursSelected: [],
      horairesSelected: [],
      myShift: undefined,
      week: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      options: [
        { name: "Lundi", code: "Lundi" },
        { name: "Mardi", code: "Mardi" },
        { name: "Mercredi", code: "Mercredi" },
        { name: "Jeudi", code: "Jeudi" },
        { name: "Vendredi", code: "Vendredi" },
        { name: "Samedi", code: "Samedi" },
        { name: "Dimanche", code: "Dimanche" }
      ],
      optionsHoraires: [
        { name: "06h00", code: "6h0" },
        { name: "08h00", code: "8h0" },
        { name: "08h30", code: "8h30" },
        { name: "09h00", code: "9h00" },
        { name: "10h45", code: "10h45" },
        { name: "11h15", code: "11h15" },
        { name: "12h00", code: "12h0" },
        { name: "13h30", code: "13h30" },
        { name: "16h15", code: "16h15" },
        { name: "19h00", code: "19h0" }
      ],
      openSwaps: [],
      favoriteSwapsIds: [],
      isDeleteModalActive: false,
      selectFrom: undefined,
      highlight: true,
      highlightedIds: [],
      selectedSwap: undefined,
      publishedSwaps: [],
      favoriteSwaps: [],
    };
  },
  mixins: [date],
  mounted() {
    this.mesinfos = this.$store.getters.getUser;
    this.volant = this.mesinfos.currentTeam.includes("volante")
    const day = this.mesinfos.currentTeam.slice(1, 5)
    const time = this.mesinfos.currentTeam.split(' ')[2]
    this.myShift = day + " - " + time
    this.init();
  },
  methods: {
    init: function () {
      swapServices
        .fetchAllSwaps()
        .then(
          response => {
            const swapsList = response.data.sort((a, b) => a.shiftDate > b.shiftDate)
            this.openSwaps = swapsList
            if (!this.volant) {
              const day = this.mesinfos.currentTeam.slice(1, 5)
              const time = this.mesinfos.currentTeam.split(' ')[2]
              swapsList.map((swap) => {
                swap.preferedShifts.map((shift) => {
                  const shiftDay = shift.split(' ')[0]
                  const shiftTime = shift.split(' ')[2]
                  if (shiftDay == day && shiftTime == time) {
                    this.highlightedIds.push(swap._id)
                  }
                })

              })
            }
          }
        )
        .catch(() => {
          this.errorAPI = true;
        });
      swapServices
        .fetchFavoriteSwaps()
        .then(
          response => {
            this.favoriteSwapsIds = response.data.map((swap) => swap._id)
          }
        )
        .catch(() => {
          this.errorAPI = true;
        });
      swapServices
        .fetchUserSwaps()
        .then(
          response => {
            this.publishedSwaps = response.data.filter((swap) => new Date(swap.shiftDate) > new Date())
          }
        )
        .catch(() => {
          this.errorAPI = true;
        });
      swapServices
        .fetchFavoriteSwaps()
        .then(
          response => {
            this.favoriteSwaps = response.data
            this.favoriteSwapsIds = response.data.map(swap => swap._id)
          }
        )
        .catch(() => {
          this.errorAPI = true;
        });
    },
    save: function (swapId) {
      swapServices
        .toggleFavorite(swapId)
        .then(
          () => {
            const index = this.favoriteSwapsIds.indexOf(swapId)
            if (index >= 0) {
              this.favoriteSwapsIds.splice(index, 1)
              this.favoriteSwaps = this.favoriteSwaps.filter((swap) => swap._id != swapId)
            } else {
              this.favoriteSwapsIds.push(swapId)
              swapServices
                .fetchSwap(swapId)
                .then(
                  response => {
                      const addedSwap = response.data
                      this.favoriteSwaps.push(addedSwap)
                    }
                  )
                  .catch(() => {
                    this.errorAPI = true;
                  })
                }
              }
            )
        .catch(() => {
          this.errorAPI = true;
        });
    },
    checkIfFavorite: function (swapId) {
      return this.favoriteSwapsIds.includes(swapId)
    },
    deleteSwap: async function (serviceId) {
      window.scroll(0,0);
      this.isDeleteModalActive = true;
      this.selectedSwap = serviceId;
    },
  },
  computed: {
    filteredSwaps() {
      let filteredSwaps = this.openSwaps;
      // 1er filtre sur les jours
      if (this.joursSelected.length > 0) {
        filteredSwaps = filteredSwaps.filter(swap => {
          return this.joursSelected.some(
            jour =>
              jour.code ===
              this.week[new Date(swap.shiftDate).getDay()]
          );
        });
      }
      // 2nd filtre sur les horaires
      if (this.horairesSelected.length > 0) {
        filteredSwaps = filteredSwaps.filter(swap => {
          const hours = new Date(swap.shiftDate).getHours();
          const minutes = new Date(swap.shiftDate).getMinutes();
          return this.horairesSelected.some(
            heure =>
              heure.code ===
              hours + "h" + minutes
          );
        });
      }
      if (this.selectFrom) {
        filteredSwaps = filteredSwaps.filter(swap => {
          return new Date(swap.shiftDate) > this.selectFrom
        });
      }
      return filteredSwaps
    },
  }
};
</script>

<style lang="css">
input.input {
  height: 41px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag, .multiselect__option--highlight, .multiselect__option--highlight:after{
  background: #ff4200;
}
.multiselect__spinner:before,
.multiselect__spinner:after {
  border-color: #ff4200 transparent transparent;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #ff4200;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #525252cc;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #525252cc;
}
</style>
<style lang="css" scoped>
.switchOption {
  margin: 20px 0px;
}
.empty {
  font-style: italic;
  margin-left: 15px;
}
.views {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
}
.view {
  padding-left: 5px;
}
.foot {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}
.line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 20px;
}
.button {
  margin-left: 20px;
}
</style>
