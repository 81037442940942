<template>
  <div>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Suppression</p>
            <button
                type="button"
                class="delete"
                @click="$emit('close')"/>
        </header>
        <section class="modal-card-body">
          <div>
            Etes-vous sûr de vouloir supprimer cette proposition d'échange ?
          </div>
        </section>
        <footer class="modal-card-foot">
            <b-button
              @click="deleteSwap"
              label="Supprimer"
              type="is-danger" />
            <b-button
                label="Annuler"
                @click="$emit('close')" />
        </footer>
    </div>
  </div>
</template>

<script>
import swapServices from "@/services/swapServices";

export default {
  name: "DeleteModal",
  props: {
    swapId: String
  },
  methods: {
    deleteSwap: function () {
      swapServices
        .deleteSwap(this.swapId)
        .then(() => {
          this.$emit('swapDeleted')
          this.$emit('close');
        })
        .catch(() => {
          this.errorAPI = true;
        });
    }
  },
};
</script>

<style lang="css" scoped>
.modal-card-head {
  text-align: center;
}
.modal-card-foot {
  display: flex;
  justify-content: flex-end;
}
</style>
